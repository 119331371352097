/* Override any Bootstrap variables.

For more information see: https://getbootstrap.com/docs/4.0/getting-started/theming/#variable-defaults

To see a full list variables, see: node_modules/bootstrap/scss/_variables.scss

For example:
$success: #ff00ff;
*/

$font-family-sans-serif: "Open Sans", "Segoe UI", roboto, "Helvetica Neue",
  arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family: "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji" !default;
