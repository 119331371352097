/* Custom CSS for this application.

Whenever possible, add the custom CSS in a component itself.
Use this file to override items that go across the entire application
or overriding some Bootstrap css.
*/

$umn-maroon: #7a0019;
$umn-gold: #fc3;

h1,
h2,
h3,
h4,
h5 {
  color: $umn-maroon;
}

.btn-success {
  background-color: $umn-gold !important;
  color: #000 !important;
  border: 1px solid rgb(255 153 51) !important;

  &:disabled {
    background-color: $gray-500 !important;
    border: 1px solid $gray-600 !important;
  }
}

main {
  padding-top: 30px;
}

.print-only-block {
  display: none;
}

// PRINT SCSS
@media print {
  .no-print {
    display: none;
  }

  .print-only-block {
    display: block;
  }

  // UofM Header/footer
  #umn-header-app-banner {
    display: none;
  }

  .umn-header-wrapper-div {
    background-color: #fff !important;
  }
}
